@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  src: url('/public/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  /* Add other font weights and styles similarly */
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: #f6e8f5;
  overscroll-behavior: none;
}

/*Main header class*/

.mainLayout {
  display: flex;
  margin: auto;
  position: relative;
  flex-direction: column;
}

.mainLayout:last-child {
  margin-top: auto;
}

.inherit-width {
  width: inherit;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 8px 18px 24px;
  /* gap: 16px; */
  box-shadow: -10px -10px 50px rgba(55, 55, 89, 0.08);
  border-radius: 12px 12px 0px 0px;
  height: 77px !important;
  z-index: 70;
}

.bottomNavButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 40px;
  height: 40px;
}

.perspective {
  perspective: 1000px;
}

.backface-hidden {
  backface-visibility: hidden;
}

.rotate-y-180 {
  transform: rotateY(180deg);
  transform-origin: center;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.neon-layer {
  position: absolute;

  bottom: -40px;
  width: 82px;
  height: 42px;
  background: linear-gradient(180deg, #00ffe0, #00ffe0);
  filter: blur(30px);
  border-radius: 50px;
}

.predictor-neon-layer {
  position: absolute;
  width: 300px;
  height: 260px;
  left: calc(50% - 300px / 2 + 10.5px);
  top: 450px;

  /* Primary */

  background: #018ffb;
  filter: blur(75px);
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
}

.carousel-feed {
  width: 100%;
}

.carousel-feed .slick-slide {
  padding-right: 10px;
}

.img-carousel-container {
  background: linear-gradient(180deg, rgba(26, 27, 47, 0) 0%, #1a1b2f 100%);
}

.carousel-league .slick-slide {
  padding-right: 12px;
}

.container-img {
  line-height: 0;
}

.custom-bottom-shadow {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px 10px 14px;
  position: absolute;
  height: 80px;
  left: 0%;
  right: 0%;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(26, 27, 47, 0) 0%, #555663 100%);
}

.custom-center-shadow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 4px;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.3) 0%, #2b2f39 100%);
  backdrop-filter: blur(2px);
}

.loading-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background-color: #081130;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-modal-carousel {
  background: red;
}

.close-button {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50px;
  position: absolute;
  top: 25px;
  left: 25px;
}

.share-button {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
}

.bottomAddScreenMessage {
  display: flex;
  background: #ffffff;
  box-shadow: -10px -10px 50px rgba(55, 55, 89, 0.08);
  border-radius: 12px 12px 0px 0px;
  z-index: 50;
}

/*Modal style*/

.modal-content-based {
  background: linear-gradient(180deg, rgba(26, 27, 47, 0) 5.33%, #1a1b2f 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 500px;
}

.bg-carousel-detail-image {
  background-size: cover;
  background-position: center;
  height: 300px;
}

.fullScreenLayout {
  max-width: 420px;
  margin: auto;
}

.grey-dark-base-color {
  color: #869ce6;
}

.in-progress-played {
  background-color: #e42f5a;
}

.carousel-tabs .slick-slide {
  padding-right: 25px;
}

.loading-screen-effect {
  position: absolute;
  width: 500px;
  height: 500px;
  left: 320px;
  top: calc(50% - 500px / 2 - 264px);
  background: #00ffe0;
  filter: blur(250px);
}

.loading-screen-effect-bottom {
  position: absolute;
  width: 500px;
  height: 500px;
  left: -250px;
  top: 760px;

  background: #017ffe;
  filter: blur(250px);
}

.sfeira-watermark-profile {
  position: absolute;
  flex-shrink: 0;
  top: 0;
  left: 0;
  opacity: 50%;
}

.sfeira-watermark-profile-image {
  width: 10rem;
  height: 15rem;
}

/* .livescore-purple-neon {
  position: absolute;
  width: 250px;
  height: 250px;
  right: -250px;
  bottom: -11px;
  top: -195px;
  filter: blur(125px);
  background: #9747ff;
}


.livescore-blue-neon {
  position: absolute;
  width: 250px;
  height: 250px;
  left: -36px;
  top: -189px;
  border: none;
  background: linear-gradient(135deg, #017ffe 20.35%, #00ffe0 98.26%);
  filter: blur(125px);
} */

.ticket-background-neon {
  position: absolute;
  margin-top: 50px;
  width: 350px;
  height: 217px;
  z-index: -1;

  background: #ffff;
  filter: blur(90px);
}

.open-predictor-button {
  background: linear-gradient(133.3deg, #017ffe 0.86%, #9747ff 81.36%);
  border-radius: 40px;
  width: 128px;
  height: 40px;
}

.toggle-indicator {
  position: absolute;
  width: 38px;
  height: 4px;
  left: calc(50% - 38px / 2 + 0.5px);
  top: 8px;

  /* Grey Dark */

  background: #8591b9;
  opacity: 0.25;
  border-radius: 4px;
}

.teams-classement-blue-neon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 4px;
  top: 35px;
  background: #3066f0;
  filter: blur(10px);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.teams-classement-orange-neon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 4px;
  top: 35px;
  background: #ff6900;
  filter: blur(10px);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.dateDayPicker {
  @apply w-full;
  @apply bg-primaryDark-background;
  @apply text-primaryLight-background;
  opacity: 0.8;
  margin: 0px !important;
  @apply px-2 py-6;
}

.dateDayPicker .rdp-month {
  @apply w-full;
}

.dateDayPicker .rdp-month > .rdp-table {
  @apply sm:w-1/2 md:w-2/3 lg:w-1/3 xl:w-1/4;
  min-width: 100%;
}
.dateDayPicker .rdp-month > .rdp-table {
  @apply mb-2;
}

.dateDayPicker-selected {
  /* background: linear-gradient(135deg, #017ffe 20.35%, #28f365 98.26%); */
  border-radius: 25;
  padding: 16px;
  background-color: red;
}

.dateDayPicker-custom-head {
  text-transform: capitalize;
}

.dateDayPicker
  .rdp-month
  > .rdp-table
  > .rdp-head
  > .rdp-head_row
  > .rdp-head_cell {
  text-transform: capitalize;
}

.sfeira-shine {
  position: absolute;
  width: 100%;
  height: 36.25rem;
  flex-shrink: 0;
  /* opacity: 0.15000000596046448; */
  top: 0;
}

.profile-purple-neon {
  position: absolute;
  width: 18.75rem;
  height: 18.75rem;
  flex-shrink: 0;
  right: -150px;
  bottom: -672px;
  top: -120px;
  left: 243px;
  border-radius: 300px;
  background: var(--secondary, #9747ff);
  filter: blur(250px);
}

.profile-blue-neon {
  position: absolute;
  width: 18.75rem;
  height: 18.75rem;
  /* flex-shrink: 0; */
  left: -149px;
  top: -162px;
  right: 242px;
  bottom: 714px;
  border-radius: 300px;
  background: var(
    --gradient-primary,
    linear-gradient(135deg, #017ffe 1.04%, #28f365 98.44%)
  );
  filter: blur(92.5px);
}

.signup-blue-neon {
  position: absolute;
  width: 31.25rem;
  height: 31.25rem;
  flex-shrink: 0;
  border-radius: 31.25rem;
  background: var(
    --gradient-primary,
    linear-gradient(135deg, #017ffe 1.04%, #28f365 98.44%)
  );
  filter: blur(175px);
  left: -350px;
  right: 143px;
  bottom: 602px;
  top: -350px;
}

.tutorial-purple-neon-top-corner {
  top: -63px;
  left: 267px;
  position: absolute;
  width: 7.8125rem;
  height: 7.8125rem;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    #017ffe 0%,
    #9747ff 100%,
    rgba(217, 217, 217, 0) 100%
  );
  fill: var(
    --gradient-secondary,
    linear-gradient(
      180deg,
      #017ffe 0%,
      #9747ff 100%,
      rgba(217, 217, 217, 0) 100%
    )
  );
  filter: blur(43.75px);
}

.tutorial-purple-neon-bottom-corner {
  position: absolute;
  width: 7.8125rem;
  height: 7.8125rem;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    #017ffe 0%,
    #9747ff 100%,
    rgba(217, 217, 217, 0) 100%
  );
  fill: var(
    --gradient-secondary,
    linear-gradient(
      180deg,
      #017ffe 0%,
      #9747ff 100%,
      rgba(217, 217, 217, 0) 100%
    )
  );
  filter: blur(43.75px);
  left: -62px;
  bottom: -70px;
}

.triangle-button-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #28f365;
  cursor: pointer;
}

.triangle-button-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #28f365;
  cursor: pointer;
}

.tutorial-white-neon {
  position: absolute;
  width: 7.3125rem;
  height: 7.3125rem;
  flex-shrink: 0;
  background-color: white;
  fill: var(--white, #fff);
  filter: blur(59.8650016784668px);
  left: 95.550048828125px;
}

.browse-left-blue-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: -37px;
  left: -19px;
  background: #018FFB;
  fill: var(--Primary, #018FFB);
  filter: blur(25.000015258789062px);
}

.browse-right-blue-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: 125px;
  left: 130px;
  background: #018FFB;
  fill: var(--Primary, #018FFB);
  filter: blur(25.000015258789062px);
}

.browse-left-green-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: -37px;
  left: -19px;
  background: #28F365;
  fill: var(--Green-Neon, #28F365);
  filter: blur(25.000015258789062px);
}

.browse-right-green-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: 125px;
  left: 130px;
  background: #28F365;
  fill: var(--Green-Neon, #28F365);
  filter: blur(25.000015258789062px);
}

.browse-left-red-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: -37px;
  left: -19px;
  background: #E42F5A;
  fill: var(--state-danger, #E42F5A);
  filter: blur(25.000015258789062px); 
}

.browse-right-red-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: 125px;
  left: 130px;
  background: #E42F5A;
  fill: var(--state-danger, #E42F5A);
  filter: blur(25.000015258789062px);
}

.browse-left-purple-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: -37px;
  left: -19px;
  background: #5865F2;
  fill: #5865F2;
  filter: blur(25.000015258789062px);
}

.browse-right-purple-neon {
  position: absolute;
  width: 4.84194rem;
  height: 4.9875rem;
  top: 125px;
  left: 130px;
  background: #5865F2;
  fill: #5865F2;
  filter: blur(25.000015258789062px);
}

.reports-gray-border > *:not(:last-child) {
  border-bottom: 1px solid #8591B9;
  width: 100%;
  padding-bottom: 10px;
}

.triangle-button-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #28f365;
  cursor: pointer;
}

.triangle-button-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #28f365;
  cursor: pointer;
}

.ticket-slide .slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
}


.triangle-button-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #28f365;
  cursor: pointer;
}

.triangle-button-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #28f365;
  cursor: pointer;
}

.ticket-slide .slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
}


.quizz-button {
   width: Fixed (365px);
height: Hug (140px);
top: 450px;
left: 14px;
padding: 10px, 20px, 10px, 20px;
gap: 20px;


}


.clock-light {
  width: 24px;
  height: 24px;
  padding: 2.44px, 2.44px, 2.44px, 2.44px;
  
}

.question {
  width: 350px;
height: 370px;
top: 188px;
left: 28px;

}

.outline-button {
  border-width: 2px;
  border-color:#FFFFFF; /* Border color for the outline */
  color:#FFFFFF; /* Text color */
  background-color: transparent; /* Transparent background */
  border-radius: 50px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
}

/* Hover effect */
.outline-button:hover {
  background-color: #3498db; /* Background color on hover */
  color: #fff; /* Text color on hover */
}

.question-p {
  width: 350px;
height: 63px;
angle: 0 deg;
font-family: Inter;
font-size: 15px;
font-weight: 600;
line-height: 21px;
letter-spacing: 0em;
text-align: left;


  color: white; /* Texte en blanc */
}

.question-p span {
  font-weight: bold; /* Texte en gras */
  font-size: 1.2em; /* Taille du texte */
  display: block; /* Pour le rendre en tant que sous-titre sur une nouvelle ligne */
  margin-bottom: 10px; /* Espacement en bas du sous-titre */
}

.anarana {
  width: Hug (161px);
height: Hug (23px);
top: 13px;
left: 102px;
gap: 10px;
text-align: center;
}

.grenoblestyle {
  width: 58px;
height: 17px;
top: 38px;
left: 154px;
opacity: 0.65px;

font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: center;

}

.question-challenge {
width: Hug (350px);
height: Hug (476px);
top: 117px;
left: 25px;
gap: 36px;

}




/* liste amie */

.conteneur {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center; /* Pour aligner le texte verticalement au centre */
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

button.badge {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}


/* challenge avec un ami*/

.image-container {
    display: flex;
    align-items: center;
 
}

.round-image {
    border-radius: 50%; 
    max-width: 100%;
    height: auto;
    margin: 10px;
}

.libelle{
  width: 33px;
  height: 27px ;
    font-size: xx-large;
    font-weight: bold;
font-family: Inter;
font-size: 23px;
font-weight: 700;
line-height: 27px;
letter-spacing: 0em;
text-align: center;
color: white;



}


/* challenge start */
.container-challenge {
  width: Fixed (344px);
  height: 68px;
  padding: 14px, 32px, 14px, 32px;
  border-radius: 12px;
  border: 0px, 0px, 1px, 0px;
  gap: 38px;
  background-color: #1A2342;
  display: flex;
  align-items: center;
  
  
  }

.score{
  width: 24px;
  height: 17px; 
font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: center;

  color: white;
  
  
  }

  /* quizz start défi*/

  .quizz-start-defi {
width: 393px;
height: 862px;
top: 11131px;
left: 1443px;

  }

  .contenent {
    width: Hug (344px);
height: Hug (377px);
top: 186px;
left: 25px;
gap: 26px;

  }

  .top {
    width: Hug (264px);
height: Hug (96px);
padding: 0px, 0px, 40px, 0px;
gap: 16px;

  }

  .p1 {
    width: 76px;
height: 17px;
font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: center;
color: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);



  }

  .p2 {
    width: 264px;
height: 23px;
angle: 0 deg;
font-family: Inter;
font-size: 19px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;



  }

  .container-defi {
    width: Fixed (344px);
height: Hug (66px);
padding: 14px, 32px, 14px, 32px;
border-radius: 12px;
gap: 38px;

  }

  .paragraphe {
    width: 344px;
height: 69px;
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;


  }

  .bouton-jouer {
width: Fixed (148px);
height: Fixed (50px);
top: 729px;
left: 122px;
border-radius: 50px;
background: #FFFFFF;

  }


  /*Resultat de challenge  et quizz*/


  

.custom-button-result {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Style du conteneur du bouton */
.button-container-result {
  text-align: center;
  margin-bottom: 20px;
}

.custom-button-point {
font-family: Inter;
font-size: 15px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
background: linear-gradient(91deg, #E2DAAC 2.72%, #807049 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
/* background-color: linear-gradient(90.64deg, #E2DAAC 2.72%, #807049 100%); */



}

.custom-text {
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%);
  background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  
}

/* Style du conteneur */
.container-result {
  display: flex;
  flex-wrap: wrap;
}

/* Style des sous-divs */
.box-result {
  width: 45%;
  padding-top: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 2%;
  text-align: center;

  
}

/* Style des labels */
label {
  font-weight: bold;
  color: white;
  text-align: left;
  padding-left: 15px;
  display: block;
  margin-bottom: 5px;
}

/* Centrer les conteneurs à l'écran */
.contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

/* Styles communs aux boutons */
.custom-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

/* Styles communs aux boutons */
.custom-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;

  font-weight: bold;
  margin-right: 10px; 
}

h2 {
font-family: Inter;
font-size: 23px;
font-weight: 700;
line-height: 27px;
letter-spacing: 0em;
color: #FFFFFF;


  
}

.blue-button {
  background-color: transparent;
  color: white; 
}

.border-button {
  border: 2px groove rgba(255, 255, 255, 0.5); 
}
.button-footer {
  display: flex;
  align-items: center;
}

h5 {
font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
    padding-left: 15px;
/* text-colo: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%); */
background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;



}





.card {
  display: inline-block;
  position: relative;
  color: #283D3B;
  width: 80px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 11px 26px 1px #0000004a, inset 0 -16px 90px #C4453640;
}

.card-1,
.card-3 {
  position: relative;
  transform: scale(.9);
}

.card-1 {
  left: 40px;
  margin-left: -60px;
  z-index: 0;
}

.card-2 {
  z-index: 1;
}

.card-3 {
  left: -40px;
  margin-right: -60px;
  z-index: 0;
}

.bg-green-500 {
  background-color: rgb(26, 236, 26)!important;
}

.bg-red-500 {
  background-color:rgb(249, 5, 5)!important ;
}

.first-header-child:first-child {
 margin-left: auto;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.embla__dot {
  appearance: none;
  background-color: #8591B9;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.embla__dot:after {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem #fff;
  background-color: #fff;
}

